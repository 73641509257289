<section class="our-products-section" [style.background]="company.globalBackGroundColor">
    <p class="products-paragraph">Our Products</p>
    <div class="text-center">
      <img src="../../assets/bhavav.png" class="bhavanimg" alt="" />
    </div>
    <div class="container images-section">
      <div class="row">
        <div class="col-md-3">
          <img src="../../assets/goldbars.png" class="goldbarsimg" alt="" />
          <p class="goldbars-paragraph mb-0">Gold Bars</p>
          <p class="text-white text-center mb-0">Bank Metal</p>
          <p class="text-white text-center mb-0">999 Purity</p>
        </div>

        <div class="col-md-3">
          <img src="../../assets/silverbars.png" class="goldbarsimg" alt="" />
          <p class="goldbars-paragraph mb-0">Silver Bars</p>
          <p class="text-white text-center mb-0">Bank Metal</p>
          <p class="text-white text-center mb-0">999 Purity</p>
        </div>

        <div class="col-md-3">
          <img src="../../assets/goldcoins.png" class="goldbarsimg" alt="" />
          <p class="goldbars-paragraph mb-0">Gold Coins</p>
          <p class="text-white text-center mb-0">Bank Metal</p>
          <p class="text-white text-center mb-0">999 Purity</p>
        </div>

        <div class="col-md-3">
          <img src="../../assets/silvercoins.png" class="goldbarsimg" alt="" />
          <p class="goldbars-paragraph mb-0">Silver Coins</p>
          <p class="text-white text-center mb-0">Bank Metal</p>
          <p class="text-white text-center mb-0">999 Purity</p>
        </div>
      </div>
    </div>
  </section>