import { Component } from '@angular/core';

@Component({
  selector: 'app-layoutmobile',
  templateUrl: './layoutmobile.component.html',
  styleUrls: ['./layoutmobile.component.scss']
})
export class LayoutmobileComponent {

}
