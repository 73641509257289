<section class="layout_padding light_bg">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="full">
          <div class="heading_main mt-3">
            <h2><span>About Gold Bharat</span></h2>
            <p>
              Founded on December 22nd, Gold Bharat is a pioneering digital platform dedicated to
              providing secure and convenient gold investment solutions. Our esteemed leadership team
              includes <b>CEO Syed Taruj Ahmed, MD Ravi Dalmiya,</b> and <b>Vice President Dhruv Dalmiya</b>.
              These seasoned professionals bring a wealth of experience and a proven track record of
              success in various sectors.
            </p>
            <h2>
              Our Mission
            </h2>
            <p>
              At Gold Bharat, we cater to B2B services for gold investors. We sell gold and silver at live
              screen rates to bullion traders, allowing both retail investors and bullion traders to book or
              buy gold in bars. Our mission is to provide a seamless and transparent trading experience,
              ensuring competitive pricing and real-time transactions.
            </p>
            <h2>
              Leadership
            </h2>
            <p>
              Our leadership team is the backbone of our success. With a vision for excellence, Syed
              Taruj Ahmed, Ravi Dalmia, and Dhruv Dalmia have successfully led numerous ventures and
              brought unparalleled expertise to Gold Bharat. Their commitment to quality and customer-
              centric approach drives our continuous growth and innovation.
            </p>
            <h2>
              Our Values
            </h2>
            <p>
              We believe in building trust through transparency. Our core values of integrity,
              accountability, and reliability guide every decision we make, ensuring our customers receive
              the best service and products.
            </p>
            <h2>
              What We Offer
            </h2>
            <p>
              1. <b>Gold & Silver Bars:</b> Competitive pricing at live screen rates.</p>
            <p>
              2. <b>Live Screen:</b> Real-time rates for transparent transactions.</p>
            <p>
              3. <b>Bank Metal:</b> Reliable and trustworthy banking metal options.</p>
            <p>
              4. <b>Door Delivery:</b> Convenient delivery service for your purchases.</p>
            <h2>
              Join Us
            </h2>
            <p>Join the Gold Bharat community and experience a platform designed for wholesale
              transactions. Our platform caters to retail investors, jewelry retailers,and bullion vendors,
              providing them the best opportunities to book or buy gold from us with ease and confidence.</p>
          </div>
          <!-- <div class="heading_main mt-3">
            <h2><span>{{heading_1}}</span></h2>
          </div>
          <div class="heading_main mt-3">
            <h3><span>{{heading_2}}</span></h3>
            <p>
              {{content_2}}
            </p>
          </div>
          <div class="heading_main mt-3">
            <h3><span>{{heading_3}}</span></h3>
            <p>
              {{content_3}}
            </p>
          </div>
          <div class="heading_main mt-3">
            <h3><span>{{heading_4}}</span></h3>
            <p>
              {{content_4}}
            </p>
          </div>
          <div class="heading_main mt-3">
            <h3><span>{{heading_5}}</span></h3>
            <p>
              {{content_5}}
            </p>
          </div>
          <div class="heading_main mt-2">
            <p>
              {{content_6}}
            </p>
          </div>
          <div class="heading_main mt-2">
            <p>
              {{content_7}}
            </p>
          </div>
          <div class="heading_main mt-2">
            <p>
              {{content_8}}
            </p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</section>