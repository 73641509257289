<!-- <section class="layout_padding light_bg">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="full">
          <div class="heading_main mt-3">
            <h2><span>GENERAL POLICY</span></h2>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <p class="blog_head">1. GENERAL CONDITION OF USE.</p>
        <p>
          Welcome to Gold Bharat ("this Website"), a website owned and
          operated by Goldsikka Private Limited. We, Goldsikka Limited and/or
          our designated affiliates (jointly "Goldsikka") provide website
          features to you, the user subject to your acceptance of the following
          Terms and Conditions of Access and Use, constituting an agreement
          between us ("Agreement"). If you continue to use this Website or
          communicate with us, you will be deemed to have accepted these Terms
          and Conditions of Access and Use. Please read these Terms and
          Conditions of Access and Use carefully. In addition, when you use any
          current or future Goldsikka service or business you will also be
          subject to the guidelines, terms and agreements applicable to such
          service or business ("Specific Terms"). If the Terms and Conditions of
          Access and Use are expressly inconsistent with such Specific Terms,
          the Specific Terms will prevail. Goldsikka may revise these Terms and
          Conditions of Access and Use from time to time and at any time,
          without notice to you. If you do not agree to be bound by these Terms
          and Conditions of Access and Use, as modified from time to time, you
          should leave this Website immediately. The section titles in these
          Terms and Conditions of Access and Use are for convenience only and
          have no legal effect.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12"></div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <p class="blog_head mt-2">
          2. ACCEPTANCE OF DISCLAIMERS AND EXCLUSIONS.
        </p>
        <p>
          You acknowledge that the disclaimers and exclusions of liability set
          forth in these Terms and Conditions of Access and Use represent a fair
          and reasonable allocation of the risks and benefits of the Agreement
          between you and us, taking all relevant factors into consideration.
          You agree that these disclaimers and limitations shall be enforceable
          to the fullest extent permitted by applicable law. You agree that
          regardless of any statute or law to the contrary, any claim or cause
          of action arising out of or related to use of this Website or these
          Terms and Conditions of Access and Use must be filed within one (1)
          year after such claim or cause of action arose or be forever barred.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12"></div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <p class="blog_head mt-2">3. BUSINESS RULES.</p>
        <p>
          These Terms and Conditions of Access and Use shall be read in
          conjunction with the Business Rules of Goldsikka and of this Website,
          as available on this Website. Goldsikka may revise these Business
          Rules from time to time and at any time without notice to you.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12"></div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <p class="blog_head mt-2">4. COPYRIGHT.</p>
        <p>
          All content included in this Website such as text, graphics, logos,
          button icons, images, audio clips, digital downloads, data
          compilations and software is the property of Goldsikka or its content
          suppliers and is protected by international copyright laws. All
          software used on this site is the property of Goldsikka or its
          software suppliers and is protected by international copyright laws.
          The content of this Website cannot be copied, reproduced, republished,
          uploaded, posted, transmitted or distributed for any non-personal use
          without the prior written approval of Goldsikka. Graphics, logos, page
          headers, button icons, scripts and service names contained in or
          features in this Website are trademarks or trade dress of Goldsikka.
          Goldsikka's trademarks may not be used in connection with any other
          product or service in any manner that is likely to cause confusion,
          disparage or discredit regarding Goldsikka and its products and
          services. All trademarks not owned by Goldsikka that appear in this
          site are the property of their respective owners, who may or may not
          be affiliated with connected to or sponsored by Goldsikka.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12"></div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <p class="blog_head mt-2">5. DISPUTES.</p>
        <p>
          Any dispute or claim relating in any way to your visit to this Website
          or to products or services sold or distributed by Goldsikka or through
          this Website will be resolved first by arbitration. The Indian
          Arbitration and Conciliation Act of 1996 applies to this agreement. To
          begin an arbitration proceeding, you must send a letter requesting
          arbitration and describing your claim in detail to our address
          available on this Website. The arbitration will be conducted in Mumbai
          by an independent arbitrator appointed by Goldsikka. The costs of
          arbitration proceedings will be borne by the party so instructed by
          the arbitrator on merits of the case. We each agree that any dispute
          resolution proceedings will be conducted only on an individual basis
          and not in a class, consolidated or representative action.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12"></div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <p class="blog_head mt-2">6. GOVERNING LAW AND JURISDICTION.</p>
        <p>
          By visiting this Website, you agree to be bound by the applicable
          Indian laws and State laws including those pertaining to taxation.
          This Agreement has been executed and delivered in India and its
          interpretations, validity and performance shall be construed and
          enforced in accordance with the laws of the Republic of India without
          regard to principles of conflict of laws and shall be subject to the
          exclusive jurisdiction of courts at Mumbai, India.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12"></div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <p class="blog_head mt-2">7. PRICING.</p>
        <p>
          represents the value of the respective product. For certain
          transactions, you shall bear fees and charges in addition to payment
          for prices displayed on this Website. Such additional fees and charges
          shall be as are stated on the website. Please pay attention to the
          details of your transactions, because your total price for a
          transaction may include taxes, fees and shipping costs.our order payment is due within 2 days after placing
          your booking order, which you are
          responsible for paying. With respect to services provided or products
          sold by Goldsikka, we cannot confirm the cost of a service or the
          price of a product until you order. Despite our best efforts, a small
          number of the items in our catalogue may be mispriced. If an item's
          correct price is higher than our stated price, we will, at our
          discretion, either contact you for instructions before shipping or
          cancel your order and notify you of such cancellation.
          A late fee of ₹110 per gram for gold and ₹1000 per kg for silver will be applied to orders not paid within the 2-day
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12"></div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <p class="blog_head mt-2">8. RETURNS, REPLACEMENT AND TITLE.</p>
        <p>
          Goldsikka does not take title to returned items until the item arrives
          at our designated address. For more information about our returns and
          replacement, please see our Replacement Policy.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12"></div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <p class="blog_head mt-2">9. Refund Policy.</p>
        <p>
          Once physical position process is started it cannot be reverted back
          but the trade can be squared up by repurchase. Goldsikka will
          repurchase Gold / Silver at market momentum rate. Customer can Refund
          their Gold from Goldsikka at any time. Transaction charges are
          applicable. If the customer wants to cancel his transaction within 24
          hours a transaction fee will be collected and remaning amount will be
          transfered to his/her bank account.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12"></div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <p class="blog_head mt-2">10. Delivery Policy.</p>
        <p>
          Gold/Silver Delivery will be in your Town / City with Registered and
          Recognized Jeweller / Bullion trader in quality and quantity verified
          tamper proof pack.<br />

          1. Delivery of your purchased Gold/Silver - Bullion will be done at
          notified Jeweller/Bullion Trader in your city/town, the customer
          kindly need to verify and satisfy with the product in terms of
          quantity and quality.<br />
          2. Goldsikka do not provide home delivery for its customers for
          security reasons.<br />
          3. Customer can collect their Gold/Silver at our registered
          partners.<br />
          4. At the time of delivery customer has to provide Id proof.
        </p>
      </div>
    </div>
  </div>
  </section> -->
  <section class="layout_padding light_bg">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="full">
            <div class="heading_main mt-3">
              <h2 ><span>{{ policy?.title }}</span></h2>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngFor="let section of policy?.sections">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <p class="blog_head ">{{ section.heading }}</p>
          <p class="mb-4">{{ section.content }}</p>
        </div>
      </div>
    </div>
  </section>
